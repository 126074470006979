/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // ********* Polyfill *********
        $(document).ready(function(){
          var elements = $('.sticky');
          Stickyfill.add(elements);
        });

        // ********* Menu searchbox *********
        $('.header__loupe').click(function(){
          $('.header__searchbox').toggleClass('show-searchbox');
          if ($('.header__searchbox').hasClass('show-searchbox')){
            $('.input-group-field').focus();
          }
          $('.header').toggleClass('searchbox-open');
        });

        // ********* Contact form custom checkbox *********
        $('.contact__form--rgpd input').change(function(){
          $(this).closest('.contact__form--rgpd').toggleClass('checked');
        });

        // ********* Menu responsive *********
        $('#burger-menu').click(function () {
          $('.header__content-holder').toggleClass('show-menu');
          $(this).toggleClass('burger-close');
        });


        $('.group-selector--current').click(function (e) {
          e.preventDefault();
          $this = $(this);
          $parent = $this.parents('.group-selector');
          $parent.toggleClass('active');
        });

        // ********* Menu thème AMP *********
        $('#menu-close').click(function () {
          $('.header__content-holder').removeClass('show-menu');
          $('#burger-menu').removeClass('burger-close');
        });

        // ********* Onglets page matière *********
        $('.onglet').click(function(){
          $('.onglet').each(function(){
            $(this).removeClass('active');
          });
          $(this).addClass('active');

          if($(this).attr('data-id')){
            $id = '#' + $(this).attr('data-id');
          }

          if($id) {
            $('.onglet-content').each(function(){
              $(this).removeClass('onglet-content--active');
            });

            $($id).addClass('onglet-content--active');
          }
        });

        // ********* Onglet contactez-nous page consommable *********
        $('.responsive-contact-us').click(function(){
          $('.cell-single-consommable__col-left').toggleClass('consommable-more-show');
        });

        // ********* Slick slider *********
        $('#home-slider').slick({
          autoplay: true,
          autoplaySpeed: 5000,
          appendArrows: $('#arrow-attacher'),
          prevArrow: '<button type="button" class="slick-prev"><i class="icons8-arrow-slider-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="icons8-arrow-slider-right"></i></button>',
          dots: true
        });

        $('#actus-slider').slick({
          autoplay: false,
          dots: true,
          infinite: true,
          arrows: false,
          centerMode: true,
          centerPadding: '5%',
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: "unslick"
            },
            {
            breakpoint: 640,
            settings: {
              centerPadding: '20%'
            }
          }
          ],
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
